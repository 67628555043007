<template>
  <b-modal
    :visible="visible"
    centered
    hide-footer
    size="lg"
    @change="$emit('change', false)"
    @show="openModal"
  >
    <template #modal-header>
      <language-selector-header
        :title="item.key ? $t('elements.edit') : $t('elements.create')"
        @closeModal="closeModal"
        @selectLanguage="(language) => (selectedLanguage = language)"
      />
    </template>

    <!-- Form -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(handleCreateItem)">
        <!-- TYPE SELECTOR-->
        <b-form-group
          :label="$t('elements.type')"
          label-for="type"
          class="mb-1"
        >
          <v-select
            id="type"
            v-model="item.type"
            :clearable="false"
            searchable
            :options="typeOptions"
            :reduce="(option) => option.code"
            label="name"
            @input="changeType"
          />
        </b-form-group>

        <!-- TYPE: text -->
        <b-form-group
          v-if="item.type === 'text'"
          :label="$t('elements.text.text')"
          label-for="text"
        >
          <quill-editor
            id="text"
            v-model="item.customizations.text[selectedLanguage]"
            class="my-50"
            rows="8"
            :value-placeholder="$t('layouts.widget-name')"
            label-attribute="surnmame"
          />
        </b-form-group>

        <!-- TYPE: Media (Image or Video) -->
        <div v-if="item.type === 'image' || item.type === 'video'">
          <!-- Media selector -->
          <b-form-group label="Image from" label-for="imageLink" class="mb-1">
            <v-select
              id="imageLink"
              v-model="isLink"
              :clearable="false"
              searchable
              :options="imageOptions"
              :reduce="(option) => option.code"
              label="name"
            />
          </b-form-group>

          <!-- ...from link -->
          <validation-provider
            v-if="isLink"
            #default="validationContext"
            :name="$t('elements.media.link')"
            rules="required"
          >
            <b-form-group
              :label="$t('elements.media.link') + ' *'"
              label-for="file-link"
            >
              <b-form-input
                id="file-link"
                v-model="item.customizations.linkURL"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('elements.media.link-placeholder')"
                :disabled="isSaving"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- ...from source -->
          <div v-else>
            <validation-provider
              #default="validationContext"
              ref="fileValidator"
              :name="$t(`elements.media.select-${item.type}`)"
            >
              <b-form-group
                :label="$t(`elements.media.select-${item.type}`)"
                label-for="image"
              >
                <file-preview
                  v-if="fileURLLink"
                  :src="getImageResource(fileURLLink)"
                  :state="getValidationState(validationContext)"
                  :mime-type="item.type"
                  cancelable
                  @cancel="
                    fileURLLink = null;
                    validationContext.validate(null);
                  "
                />
                <file-upload
                  v-else
                  v-model="fileURL"
                  :type="item.type"
                  class="w-100"
                  cancelable
                  :disabled="isSaving"
                  :placeholder="$t('media.form.file.placeholder')"
                  :drop-placeholder="$t('media.form.file.drop-placeholder')"
                  @cancel="fileURL = null"
                />
                <b-form-invalid-feedback
                  :class="{ 'd-block': validationContext.errors[0] }"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <!-- Media link -->
          <validation-provider
            v-if="item.type === 'image'"
            #default="validationContext"
            :name="$t('elements.media.button-link')"
          >
            <b-form-group
              :label="$t('elements.media.button-link')"
              label-for="file-link"
            >
              <b-form-input
                id="file-link"
                v-model="item.customizations.imageURLButton"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('elements.media.link-placeholder')"
                :disabled="isSaving"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            :label="$t('elements.image.title')"
            label-for="image-size"
          >
            <v-select
              id="image-size"
              v-model="item.customizations.imageSize"
              :clearable="false"
              :options="imageSizeOptions"
              :reduce="(option) => option.code"
              label="name"
            />
          </b-form-group>
        </div>

        <!-- TYPE: Iframe -->
        <div v-if="item.type === 'iframe'">
          <!-- link -->
          <validation-provider
            #default="validationContext"
            :name="$t('elements.media.link')"
            rules="required"
          >
            <b-form-group
              :label="$t('elements.media.link') + ' *'"
              label-for="file-link"
              description="* If a site is disallowing frames with frame-ancestors or X-Frame-Options, you simply can't iframe it. You can use a static image with a link to the URL."
            >
              <b-form-input
                id="file-link"
                v-model="item.customizations.linkURL"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('elements.media.link-placeholder')"
                :disabled="isSaving"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- TYPE: Separator -->
        <b-form-group
          v-if="item.type === 'separator'"
          :label="$t('elements.separator.type')"
          label-for="separator"
          class="mb-1"
        >
          <v-select
            id="separator"
            v-model="item.customizations.separator"
            :clearable="false"
            searchable
            :options="separatorOptions"
            :reduce="(option) => option.code"
            label="name"
            @input="changeSeparator"
          />
          <b-form-checkbox
            v-if="item.customizations.separator === 'horizontal'"
            v-model="hasSeparatorTitle"
            class="my-1"
            @change="toggleIsSeparator"
          >
            {{ $t("elements.separator.has-title") }}
          </b-form-checkbox>
          <validation-provider
            v-if="hasSeparatorTitle"
            #default="validationContext"
            :name="$t('media.form.link.name')"
            rules="required"
          >
            <b-form-group
              :label="$t('elements.separator.title') + ' *'"
              label-for="separator-title"
            >
              <b-form-input
                id="separator-title"
                v-model="item.customizations.separatorTitle[selectedLanguage]"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('elements.separator.title-placeholder')"
                :disabled="isSaving"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form-group>

        <!-- TYPE: Call to action (CTA) -->
        <div v-if="item.type === 'cta' || item.type === 'cta2'">
          <!-- CTA Title -->
          <validation-provider
            #default="validationContext"
            :name="$t('elements.cta.title')"
          >
            <b-form-group
              :label="$t('elements.cta.title')"
              label-for="cta-title"
            >
              <b-form-input
                id="cta-title"
                v-model="item.customizations.ctaTitle[selectedLanguage]"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('elements.cta.title-placeholder')"
                :disabled="isSaving"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- CTA Headline -->
          <b-form-group
            :label="$t('elements.cta.headline')"
            label-for="cta-headline"
          >
            <b-form-input
              id="cta-headline"
              v-model="item.customizations.ctaHeadline[selectedLanguage]"
              trim
              :placeholder="$t('elements.cta.headline-placeholder')"
              :disabled="isSaving"
            />
          </b-form-group>
          <!-- CTA Button title -->
          <validation-provider
            #default="validationContext"
            :name="$t('elements.cta.button')"
            rules="required"
          >
            <b-form-group
              :label="$t('elements.cta.button') + ' *'"
              label-for="cta-button"
            >
              <b-form-input
                id="cta-button"
                v-model="item.customizations.ctaButton[selectedLanguage]"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('elements.cta.button-placeholder')"
                :disabled="isSaving"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- CTA Button link -->
          <validation-provider
            #default="validationContext"
            :name="$t('elements.cta.link')"
            rules="required"
          >
            <b-form-group
              :label="$t('elements.cta.link') + ' *'"
              label-for="cta-link"
            >
              <b-form-input
                id="cta-link"
                v-model="item.customizations.ctaButtonLink"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('elements.cta.link-placeholder')"
                :disabled="isSaving"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- CTA Background image -->
          <validation-provider
            #default="validationContext"
            ref="fileValidator"
            :name="$t('elements.cta.image')"
          >
            <b-form-group :label="$t('elements.cta.image')" label-for="image">
              <file-preview
                v-if="ctaURLLink"
                :src="getImageResource(ctaURLLink)"
                :state="getValidationState(validationContext)"
                mime-type="image"
                cancelable
                @cancel="
                  ctaURLLink = null;
                  validationContext.validate(null);
                "
              />
              <file-upload
                v-else
                v-model="ctaImageURL"
                type="image"
                class="w-100"
                cancelable
                :disabled="isSaving"
                :placeholder="$t('media.form.file.placeholder')"
                :drop-placeholder="$t('media.form.file.drop-placeholder')"
                @cancel="ctaImageURL = null"
              />
              <b-form-invalid-feedback
                :class="{ 'd-block': validationContext.errors[0] }"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          <b-form-group 
            :label="$t('elements.image.title')" 
            label-for="image-size"
          >
            <v-select
              id="image-size"
              v-model="item.customizations.imageSize"
              :clearable="false"
              :options="imageSizeOptions"
              :reduce="(option) => option.code"
              label="name"
            />
          </b-form-group>
        </div>

        <!-- TYPE: widget -->
        <b-form-group
          v-if="item.type === 'widget'"
          label="App"
          label-for="widget"
        >
          <!-- App selector -->
          <v-select
            v-model="selectedWidget"
            id="widget-app"
            :clearable="false"
            :options="widgetOptions"
            :get-option-label="(widget) => widget.name[locale] || Object.values(widget.name)[0]"
            :placeholder="$t('elements.widget.placeholder')"
            :label="$t('elements.widget.placeholder')"
            @cancel="selectedWidget = null"
          />
        </b-form-group>

        <!-- Submit button -->
        <div class="float-right mb-50 mt-1">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="isSaving"
            type="submit"
          >
            <span v-if="isSaving" class="d-flex">
              <b-spinner
                class="mr-1"
                small
                variant="white"
                label="Loading..."
              />
              {{ $t("form.actions.save") }}
            </span>
            <span v-else>{{ $t("form.actions.save") }}</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import LanguageSelectorHeader from "@core/components/modal/LanguageSelectorHeader.vue";
import FileUpload from "@core/components/files/FileUpload.vue";
import FilePreview from "@core/components/files/FilePreview.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { getImageResource } from "@/@core/utils/image-utils";

export default {
  name: "ModalCreateElement",
  components: {
    vSelect,
    quillEditor,
    LanguageSelectorHeader,
    FileUpload,
    FilePreview,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    itemTo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      item: {
        customizations: {
          text: {},
          isLink: false,
          fileURL: null,
          ctaImageURL: "",
          linkURL: "",
          separator: "horizontal",
          separatorTitle: {},
          ctaTitle: {},
          ctaHeadline: {},
          ctaButton: {},
          imageSize: {},
          imageURLButton: ""
        },
        type: "text",
      },
      isNexos: process.env.VUE_APP_CUSTOMER === 'nexos',
      allowedDomains: [
        "bancolombia.com",
        "bancolombia.co",
        "bancolombia.com.co",
        "youtube.com",
        "youtu.be",
        "spotify.com",
        "open.spotify.com"
      ],
      fileURL: null,
      isLink: false,
      ctaImageURL: null,
      selectedWidget: null,
      selectedLanguage: "",
      isSaving: false,
      fileURLLink: null,
      ctaURLLink: null,
      typeOptions: [
        { name: this.$t("elements.types.text"), code: "text" },
        { name: this.$t("elements.types.image"), code: "image" },
        { name: this.$t("elements.types.video"), code: "video" },
        { name: this.$t("elements.types.separator"), code: "separator" },
        { name: this.$t("elements.types.cta"), code: "cta" },
        { name: this.$t("elements.types.cta2"), code: "cta2" },
      ],
      separatorOptions: [
        { name: this.$t("elements.separator.horizontal"), code: "horizontal" },
        { name: this.$t("elements.separator.vertical"), code: "vertical" },
      ],
      imageOptions: [
        { name: this.$t("elements.media.file-selector"), code: false },
        { name: this.$t("elements.media.link-selector"), code: true },
      ],
      imageSizeOptions: [
        { name: this.$t("elements.image.cover"), code: "cover" },
        { name: this.$t("elements.image.contain"), code: "contain" },
      ],
      required,
      hasSeparatorTitle: false,
    };
  },
  computed: {
    currentLocale() {
      const { currentLocale } = this.$store.state.locale;
      return currentLocale;
      //return this.$store.state.locale.currentLocale;
    },
    widgetOptions() {
      const modelTypesObj = Object.values(this.$store.getters.apps?.apps);
      let widgets = [];
      //use only widgets that are not already in layout or menu...
      modelTypesObj.forEach((app) => {
        if(app.inLayout){
          //widgets.push(app.name[this.currentLocale]);
          widgets.push(app);
        }
      });
      return widgets;
    },
    typeOptions() {
      let options = [
        { name: this.$t("elements.types.text"), code: "text" },
        { name: this.$t("elements.types.image"), code: "image" },
        { name: this.$t("elements.types.video"), code: "video" },
        { name: this.$t("elements.types.separator"), code: "separator" },
        { name: this.$t("elements.types.cta"), code: "cta" },
        { name: this.$t("elements.types.cta2"), code: "cta2" },
      ];

      // Only add iframe option if not Nexos
      if (!this.isNexos) {
        options.push({ name: this.$t("elements.types.iframe"), code: "iframe" });
      }

      return options;
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});

    return {
      getValidationState,
    };
  },
  methods: {
    closeModal() {
      this.$emit("change", false);
    },
    openModal() {
      this.selectedLanguage = this.currentLocale;
      if (this.itemTo) {
        const { customizations, ...newItem } = this.itemTo;
        newItem.customizations = {
          ...this.item.customizations,
          ...this.itemTo.customizations,
        };
        this.item = newItem;

        if (["image", "video"].includes(this.item.type)) {
          this.isLink = this.item.customizations?.isLink;

          if (this.item.type === "image" && !this.isLink) {
            this.fileURLLink = this.item.content;
          }
        }
        if (this.item.type === "text") {
          this.item.customizations.text = {
            ...this.itemTo.customizations.text,
          };
        }
        if (this.item.type === "separator") {
          this.hasSeparatorTitle =
            Object.values(this.item.customizations.separatorTitle).length > 0;
          if (this.hasSeparatorTitle) {
            this.item.customizations.separatorTitle = {
              ...this.itemTo.customizations.separatorTitle,
            };
          }
        }
        if (this.item.type === "cta" || this.item.type === "cta2") {
          this.ctaURLLink = this.item.customizations.ctaURLLink;
          this.item.customizations.ctaButton = {
            ...this.itemTo.customizations.ctaButton,
          };
          this.item.customizations.ctaHeadline = {
            ...this.itemTo.customizations.ctaHeadline,
          };
          this.item.customizations.ctaTitle = {
            ...this.itemTo.customizations.ctaTitle,
          };
          this.item.customizations.imageSize = {
            ...this.itemTo.customizations.imageSize,
          };
        }
      }
    },
    changeType() {
      if (this.itemTo &&
        this.item.type === this.itemTo.type &&
        this.itemTo.customizations.linkURL
      ) {
        this.item.customizations.linkURL = this.itemTo.customizations.linkURL;
      } else {
        this.item.customizations.linkURL = "";
      }
    },
    changeSeparator() {
      if (this.item.customizations.separator === "vertical") {
        this.hasSeparatorTitle = false;
      }
    },
    toggleIsSeparator() {
      if (!this.item.customizations.separatorTitle) {
        this.item.customizations = {
          ...this.item.customizations,
          separatorTitle: {},
        };
      }
    },
    toggleIsLink() {
      if (this.isLink) {
        this.file = {};
        this.item.customizations = {};
      } else {
        this.item.customizations.linkURL = "";
        this.item.customizations = this.fileURLLink;
      }
    },
    getImageResource(image) {
      return getImageResource(image);
    },
    // Add helper functions for link validation
    validateURL(url) {
      if (!url || url.trim() === "") {
        return true; // Empty URLs are always valid
      }
      return this.allowedDomains.some(domain => url.includes(domain));
    },
    containsLinks(text) {
      if (!text) return false;
      // Check for common HTML link patterns
      return /<a[^>]*href=["'](.*?)["']/i.test(text) || /https?:\/\/[^\s]+/i.test(text);
    },
    async handleCreateItem() {
      this.isSaving = true;
      try {
        const requestConfig = { 
          type: this.item.type, 
          customizations: {} 
        };
        const file = {};

        // Nexos link validation
        if (this.isNexos) {
          // Validate CTA links
          if ((this.item.type === "cta" || this.item.type === "cta2") && 
              !this.validateURL(this.item.customizations.ctaButtonLink)) {
            this.notifyError(this.$t("error-message.invalid-domain"));
            this.isSaving = false;
            return;
          }

          // Validate image/video links
          if ((this.item.type === "image" || this.item.type === "video") && 
              !this.validateURL(this.item.customizations.imageURLButton)) {
            this.notifyError(this.$t("error-message.invalid-domain"));
            this.isSaving = false;
            return;
          }

          // Validate text content links
          if (this.item.type === "text" && 
              this.item.customizations.text[this.selectedLanguage] &&
              this.containsLinks(this.item.customizations.text[this.selectedLanguage]) &&
              !this.validateURL(this.item.customizations.text[this.selectedLanguage])) {
            this.notifyError(this.$t("error-message.invalid-domain"));
            this.isSaving = false;
            return;
          }

          // Block iframe elements
          if (this.item.type === "iframe") {
            this.notifyError(this.$t("error-message.iframe-not-allowed"));
            this.isSaving = false;
            return;
          }
        }

        //Get Element Customizations...
        //TEXT
        if (this.item.type === "text") {
          requestConfig.customizations.text = this.item.customizations.text;
        }
        //IMAGE OR VIDEO
        if (this.item.type === "image" || this.item.type === "video") {
          requestConfig.customizations.isLink = this.isLink;
          requestConfig.customizations.imageURLButton =
            this.item.customizations.imageURLButton;
          requestConfig.customizations.imageSize =
            this.item.customizations.imageSize;
            
          if (this.isLink) {
            requestConfig.customizations.linkURL =
              this.item.customizations.linkURL;
          } else if (this.fileURL) file.fileURL = this.fileURL;
        }
        //IFRAME
        if (this.item.type === "iframe") {
          requestConfig.customizations.linkURL =
            this.item.customizations.linkURL;
        }
        //SEPARATOR
        if (this.item.type === "separator") {
          requestConfig.customizations.separator =
            this.item.customizations.separator;
          if (
            this.item.customizations.separator === "horizontal" &&
            this.hasSeparatorTitle
          ) {
            requestConfig.customizations.separatorTitle =
              this.item.customizations.separatorTitle;
          }
        }
        //CALL TO ACTION
        if (this.item.type === "cta" || this.item.type === "cta2") {
          requestConfig.customizations.ctaTitle =
            this.item.customizations.ctaTitle;
          requestConfig.customizations.ctaHeadline =
            this.item.customizations.ctaHeadline;
          requestConfig.customizations.ctaButton =
            this.item.customizations.ctaButton;
          requestConfig.customizations.ctaButtonLink =
            this.item.customizations.ctaButtonLink;
          requestConfig.customizations.imageSize =
            this.item.customizations.imageSize;
          if (this.ctaImageURL){
            file.ctaImageURL = this.ctaImageURL;
          } 
        }
        //WIDGET
        if (this.item.type === "widget") {
          console.log('selected widget:', this.selectedWidget);
          //Publish this widget:
          await this.$store.dispatch('editItem', {
            item: {
              itemType: 'publishAddon',
              customName: 'addons',
              requestConfig: {
                addonKey: this.selectedWidget.key,
              },
            },
          });
        }

        //console.log('this.item', this.item);

        // Check if Edit or Create
        if (this.item.key) {  
          // EDIT ELEMENT
          console.log('Editing an existing element...');
          this.item.customizations = requestConfig.customizations;
          requestConfig.key = this.item.key;
          const response = await this.$store.dispatch("editItem", {
            item: {
              itemType: "elements",
              requestConfig,
            },
            file,
          });
          this.itemTo = response.data;
          this.$emit("edited", response.data);
        } else {  
          // CREATE ELEMENT
          console.log('Creating a new element...');
          const response = await this.$store.dispatch("createItem", {
            item: {
              itemType: "elements",
              requestConfig,
            },
            file,
          });

          console.log('response', response);

          this.item = {
            customizations: {
              text: {},
              isLink: false,
              fileURL: null,
              ctaImageURL: null,
              linkURL: "",
              imageURLButton: "",
              separator: "horizontal",
              separatorTitle: {},
              ctaTitle: {},
              ctaHeadline: {},
              ctaButton: {},
              imageSize: {},
            },
            type: "text",
          };
          this.fileURL = null;
          this.isLink = false;
          this.ctaImageURL = null;
        }
        this.isSaving = false;
        this.notifySuccess(this.$t("success-message.general-success-create"));
        this.closeModal();
      } catch (error) {
        console.log('error', error);
        this.notifyError(this.$t("error-message.general-error"));
        this.isSaving = false;
      }
    },
  },
};
</script>
